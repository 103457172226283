<template>
  <div>
    <PageHeader title="Flyers" />

    <div class="tw-my-8 tw-pb-16">
      <div class="tw-grid md:tw-grid-cols-2 tw-gap-4">
        <FormulateForm
          #default="{ hasErrors }"
          v-model="values"
          name="createFlyer"
          invalid-message="Gelieve de verplichte velden correct in te vullen."
        >
          <FormulateInput
            type="select"
            name="template"
            label="Template"
            placeholder="Selecteer een template"
            :options="templates"
            validation="required"
            @input="templateSelected"
          />
          <FormulateInput
            type="checkbox"
            name="snit"
            label="Snijlijn"
          />
          <FormulateInput
            v-for="count in languageCount"
            :key="count"
            v-model="languages[count]"
            type="select"
            :name="`lang${count}`"
            :label="`Taal ${count}`"
            :placeholder="`Selecteer taal ${count}`"
            :options="USER_LANG_OPTIONS"
            :value="['nl', 'fr', 'en'][count - 1]"
            validation="required"
          />
          <Tabs v-if="languageTabs.length" :tabs="languageTabs" class="tw-my-4">
            <div v-for="(count, index) in languageCount" :key="count" :slot="`lang${count}`">
              <FormulateInput
                v-model="titleFlyer[index]"
                type="text"
                :name="`title_flyer_${count}`"
                :value="TITLE_FLYER_DEFAULTS[languagesArray[index]]"
                label="Titel flyer"
                :outer-class="['tw-mt-0']"
                validation="required:trim"
              />
            </div>
          </Tabs>
          <div v-if="propertyTabs.length && languageTabs.length" class="tw-my-4">
            <label class="formulate-label">Panden selecteren</label>
            <Tabs :tabs="propertyTabs" class="tw-my-1">
              <div v-for="(eCount, entityIndex) in entityCount" :key="eCount" :slot="`property${eCount}`">
                <!--
                  minPictures: If the template has an entity_count of 1, then the min pictures should be 3. In other cases, it's 1.
                  noLinkedOffice: Every selected property should have a office linked to it.

                  NOTE: For the validations to work correctly, the properties[entityIndex] needs to be updated after fetching printData.
                  That happens when we add the selectedPicture.
                 -->
                <FormulateInput
                  type="autocomplete"
                  auto-complete-type="property"
                  :name="`property${eCount}`"
                  :label="`Pand ${eCount}`"
                  placeholder="Selecteer een pand"
                  validation="^required|min_pictures|no_linked_office"
                  :validation-rules="{
                    minPictures: () => printData[entityIndex] && printData[entityIndex].pictures.length >= (entityCount === 1 ? 3 : 1),
                    noLinkedOffice: () => printData[entityIndex] && printData[entityIndex].office_name
                  }"
                  :validation-messages="{
                    minPictures: `Het pand moet minimaal ${ entityCount === 1 ? 'drie foto\'s' : 'een foto' } hebben`,
                    noLinkedOffice: 'Selecteer een pand met gekoppelde kantoren'
                  }"
                  @input="selectedProperty(entityIndex, $event)"
                />
                <template v-if="printData[entityIndex]">
                  <Tabs :tabs="languageTabs" class="tw-my-4">
                    <div v-for="(lCount, langIndex) in languageCount" :key="lCount" :slot="`lang${lCount}`">
                      <FormulateInput
                        v-model="printData[entityIndex].title[langIndex]"
                        type="text"
                        :name="`title${lCount}`"
                        label="Titel"
                        :outer-class="['tw-mt-0']"
                        ignored
                      />
                      <FormulateInput
                        v-model="printData[entityIndex].description[langIndex]"
                        type="textarea"
                        :name="`description${lCount}`"
                        label="Omschrijving"
                        :outer-class="['tw-mb-0']"
                        ignored
                      />
                    </div>
                  </Tabs>
                  <PictureSelector
                    v-model="properties[entityIndex].selected_pictures"
                    :pictures="printData[entityIndex].pictures"
                    :id-prefix="`property_${entityIndex}_picture_`"
                    :min="entityCount === 1 ? 3 : 1"
                    :max="entityCount === 1 ? 3 : 1"
                  />
                </template>
              </div>
            </Tabs>
            <span v-show="!propertiesValid" class="input-error">
              Selecteer a.u.b. alle panden
            </span>
          </div>

          <FormulateErrors />

          <div class="tw-flex tw-flex-row tw-gap-2">
            <FormulateInput
              type="submit"
              title="Voorbeeld"
              :disabled="generatingPreview || generatingFlyer"
              :input-class="['tw-w-full']"
              outer-class="tw-m-0 tw-flex-grow"
              @click="submit(true, hasErrors)"
            >
              <i
                :class="[
                  'fas tw-mr-1',
                  generatingPreview ? 'fa-spinner-third fa-spin' : 'fa-eye'
                ]"
              />
              Voorbeeld
            </FormulateInput>
            <FormulateInput
              v-if="flyerPreviewed"
              type="submit"
              title="Maak flyer"
              :disabled="generatingPreview || generatingFlyer"
              :input-class="['tw-bg-primary tw-w-full']"
              outer-class="tw-m-0 tw-flex-grow"
              @click="submit(false, hasErrors)"
            >
              <i
                :class="[
                  'fas tw-mr-1',
                  generatingFlyer ? 'fa-spinner-third fa-spin' : 'fa-scroll'
                ]"
              />
              Maak flyer (betalend)
            </FormulateInput>
          </div>
        </FormulateForm>
        <iframe
          v-if="pdfUrl"
          id="pdf_frame"
          type="application/pdf"
          :src="pdfUrl"
          class="tw-my-4 tw-w-full tw-h-full tw-min-h-[468px]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

import PageHeader from '@/components/PageHeader.vue'
import Tabs from '@/components/Tabs.vue'
import PictureSelector from '@/components/PictureSelector'

import { createV2Document, getV2Templates, pollV2Gutenborg, getModules } from '@/services/gutenborgService'
import { getPropertyPrintData } from '@/services/properties'
import { errorModal } from '@/modalMessages'
import { poll, USER_LANG_OPTIONS } from '@/utils/helpers'
import { createLog } from '@/services/apiService'

export default {
  name: 'Flyer',
  components: {
    PageHeader,
    Tabs,
    PictureSelector
  },
  constants: {
    USER_LANG_OPTIONS,
    TITLE_FLYER_DEFAULTS: {
      nl: 'Wat wij zoal in huis hebben.',
      fr: 'Ce que nous avons à offrir.',
      en: 'What we have to offer.'
    }
  },
  data () {
    return {
      templates: [],
      languageCount: 0,
      entityCount: null,
      languages: {},
      values: {
        snit: false
      },
      titleFlyer: [],
      properties: {},
      printData: {},
      flyerPreviewed: false,
      generatingPreview: false,
      generatingFlyer: false,
      pdfUrl: null
    }
  },
  computed: {
    propertyId () {
      return this.$route.params.id
    },
    languageTabs () {
      const tabs = []
      // Language count starts with 1
      for (let count = 1; count <= this.languageCount; count++) {
        const name = this.languages[count] // language
        if (name) tabs.push({ name: name.toUpperCase(), target: `lang${count}` })
      }
      return tabs
    },
    propertyTabs () {
      const tabs = []
      for (let count = 1; count <= this.entityCount; count++) {
        tabs.push({ name: `#${count}`, target: `property${count}` })
      }
      return tabs
    },
    languagesArray () {
      return Object.values(this.languages)
    },
    propertiesValid () {
      const properties = Object.values(this.properties)
      return properties.length === this.entityCount && properties.every(property => property.id)
    }
  },
  watch: {
    languagesArray (languages) {
      languages.forEach((lang, index) => {
        this.$set(this.titleFlyer, index, this.TITLE_FLYER_DEFAULTS[lang])
      })

      const promises = Object.values(this.properties).map((property, index) => {
        return this.fetchPropertyPrintData(index, property.id, languages)
      })
      return Promise.all(promises)
    },
    entityCount (newLength, oldLength) {
      // Remove extra array items when the count changes
      if (newLength > oldLength) return true
      for (let i = oldLength; i > newLength; i--) {
        delete this.properties[i - 1]
        delete this.printData[i - 1]
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      try {
        const [templates, modules] = await Promise.all([
          getV2Templates({ type: 'flyer' }),
          getModules()
        ])

        this.templates = templates.data.map(({ id, name, ...template }) => {
          return {
            id,
            value: { id, ...template },
            label: name
          }
        })

        return [templates, modules]
      } catch (error) {
        errorModal('Flyer-module kan niet worden geïnitialiseerd.')
      }
    },
    templateSelected (template) {
      const { language_count, output_type, entity_count } = template
      this.languageCount = language_count || 0
      this.entityCount = entity_count || 1
      this.$set(this.values, 'legal_notices_output_type', output_type)
      this.$set(this.values, 'entity_count', entity_count)
    },
    async selectedProperty (entityIndex, property) {
      if (!property) {
        delete this.properties[entityIndex]
        delete this.printData[entityIndex]
        return null
      }

      this.$set(this.properties, entityIndex, property)
      const response = await this.fetchPropertyPrintData(entityIndex, property.id, this.languagesArray)
      const selected_pictures = this.entityCount === 1 ? response.data.pictures?.slice(0, 3) : [response.data.pictures?.[0]]
      this.$set(this.properties[entityIndex], 'selected_pictures', selected_pictures)
      return response
    },
    async fetchPropertyPrintData (entityIndex, propertyId, languages) {
      try {
        if (!(languages.length && this.values?.legal_notices_output_type)) return

        // For the flyers, the legal_notices_output_type will never change, so we don't need to watch for it's changes.
        const payload = {
          propertyId,
          params: { languages, output_type: this.values.legal_notices_output_type, force_show_epc_uc: true, source: 'flyer' }
        }
        const response = await getPropertyPrintData(payload)
        this.$set(this.printData, entityIndex, response.data)
        this.$set(this.printData[entityIndex], 'title', response.data.title)
        this.$set(this.printData[entityIndex], 'description', response.data.description)
        return response
      } catch (error) {
        errorModal('Printgegevens konden niet worden geladen')
      }
    },
    async submit (preview, hasErrors) {
      // The function doesn't wait for the validations of the nested property selections.
      if (hasErrors) return // If validation errors are there, return.

      try {
        const { template, ...values } = this.values
        preview ? this.generatingPreview = true : this.generatingFlyer = true
        const printData = cloneDeep(this.printData)

        if (this.entityCount === 1) {
          // We select the first two photos after filtering out the selected picture.
          const { selected_pictures } = this.properties[0]
          printData[0].pictures = selected_pictures
        } else {
          // Put the selected picture object in an array
          for (let idx = 0; idx < this.entityCount; idx++) {
            const { selected_pictures } = this.properties[idx]
            printData[idx].pictures = selected_pictures
          }
        }

        const payload = {
          type: 'flyer',
          template: template.id,
          preview,
          data: Object.values(printData),
          ...values
        }
        // It's a hack required by the BE to get the title_flyer, read https://dewaele.atlassian.net/browse/DEW-5836 for more info
        payload.data[0].title_flyer = this.titleFlyer

        const generate = await createV2Document(payload)
        const pollResult = await poll(generate?.data?.job_id, pollV2Gutenborg, 1000)
        this.pdfUrl = pollResult.url

        if (preview) {
          this.flyerPreviewed = true
        } else {
          const payload = {
            action_id: 11, // Flyer action id
            meta: { name: template.name }
          }
          await createLog(payload)
        }
        return pollResult
      } catch (error) {
        console.error(error)
        errorModal('Er is iets misgegaan bij het genereren van uw flyer, probeer het opnieuw')
      } finally {
        preview ? this.generatingPreview = false : this.generatingFlyer = false
      }
    }
  }
}
</script>
